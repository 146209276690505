import React, {Component} from 'react';
import Footer from "../footer/footer";
import Header from "../header/header";
import BookList from "../book-list/book-list";
import booksJson from "../../books";
import {BrowserRouter, Link, Route, useLocation, Switch, NavLink} from "react-router-dom";
import Book from "../book/book"
import {Helmet} from "react-helmet";
import Tags from "../tags/tags";

class App extends Component {

    state = {
        slug: "/",
        book: ""
    }

    setOnLink = (slug) => {
        const kniga = [...booksJson.filter((e) => e.slug === slug)];

        this.setState(() => {
            return {
                book: kniga[0],
                slug: slug
            }
        });

        return this.state.book;

    }

    render() {

        return (

            <div className="container w-100">
                <Helmet>
                    <title>Аудио Книги</title>
                    <meta name="keywords" content="Аудио, книги, уммах"/>
                    <meta
                        name="description"
                        content="аудио книги уммах"
                    />
                </Helmet>
                <BrowserRouter>
                    <NavLink to="/" exact></NavLink>
                    <Header/>
                    <Route path="/">
                        <p>
                            <NavLink to="/" exact
                                     className="btn badge badge-pill  "
                                     activeClassName="  badge-success ">Все книги
                            </NavLink>
 
                            <NavLink to="/audiokniga/koran"
                                     className="btn badge badge-pill  "
                                     activeClassName="  badge-success ">
                                Коран с переводом на русский
                            </NavLink>
                            <Tags data={booksJson}/>

                        </p>
                    </Route>

                    <Route path="/" exact>
                        <BookList data={booksJson} tag="" onLink={this.setOnLink}/>
                    </Route>

                    <Route path="/tags/:slug" exact
                           render={({match}) => {
                               return <BookList data={booksJson} tag={match.params.slug} onLink={this.setOnLink}/>;
                           }}
                    />

                    <Route path="/audiokniga/:slug" exact
                           render={({match, history}) => {

                               const kniga = booksJson.filter((e) => e.slug === match.params.slug)[0];

                               if (match.params.slug === "koran") {
                                   return <Book data={booksJson[0]} isQuran={"mahmud-halil-alhusari-murattil"}/>;
                               }

                               if (!kniga) {
                                   return (
                                       <div>
                                           <h1 className="text-center">Книга не найдена!</h1>
                                           <BookList data={booksJson} onLink={this.setOnLink}/>
                                       </div>)
                               }

                               return <Book data={kniga}/>
                           }
                           }
                    />

                    <Route path="/audiokniga/koran/:slug" exact
                           render={({match, history}) => {

                                if (match.params.slug === match.params.slug) {
                                   return <Book data={booksJson[0] } isQuran={match.params.slug}/>;
                               }

                           }
                           }
                    />


                </BrowserRouter>

                <Footer/>
            </div>
        );
    }
}

export default App;
