import React from "react";
import 'react-h5-audio-player/lib/styles.css';
import "./book.css";
import {withRouter} from 'react-router-dom'
import {Helmet} from "react-helmet";
import Player from "../player/player";
import Tags from "../tags/tags";
const Book = props => {

     return (
        <div className="application">

            <Helmet>
                <title>Аудиокнига: {props.data.title}</title>
                <meta name="keywords" content={props.isQuran?"Коран с переводом на русский язык":props.data.tags}/>
                <meta
                    name="description"
                    content={props.isQuran?"Коран с переводом на русский язык":props.data.description}
                />
            </Helmet>

            <h1>{props.data.title} &nbsp;
                <button
                    type="button"
                    onClick={() => props.history.goBack()}
                    className="btn btn-outline-primary btn-sm ">
                    &larr; Вернуться на главную
                </button>
            </h1>

            { props.data.description}

            <Player data={props.data} isQuran={props.isQuran}/>

        </div>
    );

}
export default withRouter(Book)