import React, {Component} from "react";
import AudioPlayer from 'react-h5-audio-player';
import Down from "../book/download.png";
import Share from "../book/share.png";
import Tags from "../tags/tags";
import transliterate from "../../libraries/transliterate/transliterate";

class Player extends Component {
    mp3link = (id = this.state.currentPlaying) => {

        const qari = this.props.data.reciters.filter(el=>transliterate(el.name) === this.props.isQuran)[0].mp3;

        const zeros = (id.id < 10 && id.id > 0) ? "00" : (id.id < 100 && id.id > 9) ? "0" : "";
        return "https://s3.ummahbooks.com/audioknigi/audioknigi/quran-rus/" + qari + "/" + zeros + id.id + ".mp3";
    }

    handleOnClick = (file) => {
        navigator.share({
            title: file.name,
            text: "Слушать аудиокнигу на audio.ummahbooks.com: " + file.name,
            url:   this.isQuran?this.mp3link():file.mp3,
            //files: filesArray
        })
    }

    state = {
        currentPlaying: this.props.data.chapters[0],
        current: 0,
        autoplay: false
    }



    setCurrent = (id) => {
        this.setState(() => {
                return {
                    currentPlaying: id < 1 ? 0 : this.props.data.chapters[id - 1],
                    current: id < 1 ? 0 : id - 1,
                    autoplay: true
                }
            }
        )
    }

    render() {
         return (
            <div>

                {this.props.isQuran ?
                    <div className="alert alert-secondary" role="alert"><Tags data={this.props.data} isQuran={this.props.isQuran} /></div> : null}
                <AudioPlayer
                    onPlay={() => {
                        this.setState(() => {
                            return {autoplay: true}
                        })
                    }
                    }
                    src={this.props.isQuran ? this.mp3link() : this.state.currentPlaying.mp3}
                    showLoopControl={false}
                    showSkipControls
                    showVolumeControl={true}
                    autoPlay={this.state.autoplay}
                    onClickNext={() => this.setCurrent(this.state.current === (this.props.data.chapters.length - 1) ? '1' : this.state.current + 2)}
                    onClickPrevious={() => this.setCurrent(this.state.current)}
                    onEnded={() => this.setCurrent(this.state.current === (this.props.data.chapters.length - 1) ? '1' : this.state.current + 2)}

                />

                {


                    this.props.data.chapters.map(e => {
                        if (e.id - 1 === this.state.current) {
                            this.klass = "list-group-item list-group-item-action d-flex justify-content-between align-items-center list-group-item-primary  ";
                        } else {
                            this.klass = "list-group-item list-group-item-action d-flex justify-content-between align-items-center ";

                        }

                        return (
                            <div key={e.id} className="list-group  ">

                                <div
                                        className={this.klass} onClick={() => this.setCurrent(e.id)}>
                                    {e.name}
                                    <span style={{padding: "0.1em", marginRight: "-10px", whiteSpace: "nowrap"}}>

                                        <a href={this.props.isQuran ? this.mp3link() : e.mp3}><img src={Down}
                                                                                                   alt="Скачать"/></a>
                                        {navigator.share ?
                                            <img src={Share} alt="Скачать"
                                                 onClick={() => this.handleOnClick(e)}/> : null}
                                     </span>
                                </div>
                            </div>
                        )
                    })}
            </div>
        )
    }
}

export default Player;