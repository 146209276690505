import React from "react";

const Header = props => {
    return (<div>
        <h1 className="text-center ">Аудио версии книг издательства UMMAH</h1>
            <h3 className="text-center">Слушай сколько можешь — плати сколько хочешь!</h3>

            {/*<form action="https://yookassa.ru/integration/simplepay/payment" method="post" acceptCharset="utf-8" >*/}
            {/*    <div className="container" style={{width: '290px'}}>*/}
            {/*        <div className="form-row" >*/}
            {/*            <input name="sum" className="col-3 form-control" placeholder="0.00₽" type="text" maxLength="7" size="8" pattern="\d*" step="any" />*/}
            {/*            <button data-text="Заплатить" className="btn btn-primary mb-2" >плати сколько хочешь</button>*/}
            {/*            <input name="shopId" type="hidden" value="727630"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</form>*/}

    </div>)
}

export default Header;