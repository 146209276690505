import React from "react";
import {BrowserRouter, Link, NavLink, Route, Switch} from "react-router-dom";
import transliterate from "../../libraries/transliterate/transliterate";
const Tags = ({data, click, isQuran}) => {

    let tagsList = [];

    if (isQuran) {
        data.reciters.map((el) => {
            return tagsList += el.name.split(",") + ",";
        });
    }

    if (!isQuran) {
        data.filter(data => data.id !== 1).map((el) => {
            return tagsList += el.tags.split(",") + ",";
        });
    }

    const tegi = [...new Set(tagsList.split(","))];

    const cssClasses = "btn badge badge-pill " + (1 == 1 ? "badge-warning" : "badge-warning");


    let url = isQuran ? '/audiokniga/koran/' : '/tags/';

    return (

        tegi.map((el, id) => {
            return (<NavLink key={id} to={url + transliterate(el)}
                             className="btn badge badge-pill  "
                             activeClassName="  badge-success "
                             value={transliterate(el)}
                             id={id}>{el}
            </NavLink>)
        })
    )
}

export default Tags;