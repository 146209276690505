import React from "react";
import "./book-list.css"
import {Link} from "react-router-dom";
import transliterate from "../../libraries/transliterate/transliterate";
const BookList = ({data, onLink, tag}) => {

 const list = data.filter(data=>data.id !== 1).map((item) => {
        const {id, ...itemsProps} = item;

        if (transliterate(itemsProps.tags).includes(tag)) {

            const author = itemsProps.author ? itemsProps.author: null;
            const translator = itemsProps.translator ? <small className="text-muted">({itemsProps.translator})</small>: null;

            return (

                <li key={id} className="list-group-item">
                    <h4 className="mb-1">
                        <Link
                            to={"/audiokniga/"+itemsProps.slug}
                            onClick={() => onLink(itemsProps.slug)}>
                            {itemsProps.title}
                        </Link>
                    </h4>

                    {author}

                    {translator}
                </li>
            )
        }
    return true;
    });
    return (
        <div>
            {list}
        </div>
    );
}

export default BookList;